/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  dimensionMillion: 'Million',
  dimensionBillion: 'Billion',
  dimensionThousand: 'Thousand',
  dimensionDays: 'Days',
  dimensionDay: 'Day',
  dimensionWeeks: 'Weeks',
  dimensionWeek: 'Week',
  dimensionMonths: 'Months',
  dimensionMonth: 'Month',
  dimensionYears: 'Years',
  dimensionForever: 'Forever',
  dimensionAfterOnly: 'After only',
  dimensionCountries: 'Countries'
})
